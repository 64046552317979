import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  api: 'http://localhost:3001/',
  graphqlHost: 'https://sce-contactservice-uat.azurewebsites.net/graphql',
  auditGraphqlHost: 'https://sceaudit-uat.azurewebsites.net/graphql',
  findingGraphqlHost: 'https://scefindings-uat.azurewebsites.net/graphql',
  certificateGraphqlHost:
    'https://sce-certservice-uat.azurewebsites.net/graphql',
  scheduleGraphqlHost:
    'https://sce-scheduleservice-uat.azurewebsites.net/graphql',
  appInsights: {
    instrumentationKey: 'f5f1068e-131c-48c4-bd63-e4398d47fd87',
  },
  documentsApi: 'https://scedocservice-uat.azurewebsites.net/api/Documents',
};
